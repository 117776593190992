import React, {Fragment, useState} from 'react';

import Header from '../components/Header'
import Slider from '../components/Slider/home-one'
import About from '../components/About/home-one'
import Features from '../components/Features'
import Services from '../components/Services'
import Team from "../components/Team/home-one";
import Blog from "../components/Blog";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import Testimonials from "../components/Testimonials/home-one";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import TickerTape from '../components/TickerTape';
import PricingTable from '../components/PricingTable'
import CourseCard from '../components/CourseCard/CourseCard';
import StylesheetComponent from '../components/StylesheetComponent';
import IndexStylesheetComponent from '../components/IndexStylesheetComponent';

const HomeOne = () => {
    const [title, setTitle] = useState('');

    const handleTitleClick = (clickedTitle) => {
        setTitle(clickedTitle);
    };

    return (
        <Fragment>
            <StylesheetComponent color={'black'} />
            <IndexStylesheetComponent />
            <TickerTape />
            <Header onTitleClick={handleTitleClick}/>
            <Slider title={title} />
            <About/>
            <Features classes="sp-top"/>
            {/* <Services classes="sm-top-wt"/> */}
            {/* <PricingTable /> */}
            <CourseCard />
            <Testimonials/>
            <Team/>
            {/* <Blog/> */}
            {/* <BrandLogo/> */}
            <Funfact/>
            <CallToAction/>
            <Footer/>
            <MobileMenu onTitleClick={handleTitleClick} />
            <LoginRegister/>
        </Fragment>
    );
};

export default HomeOne;