import React, {useState, useCallback} from 'react';
import {Link} from 'react-router-dom'
import Carousel, { Modal, ModalGateway } from 'react-images';

function BlogItem(props) {
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const images = [{ src: require('../../assets/img/' + props.thumb) }];

    const openLightbox = useCallback((index) => {
        console.log('abc')
        setCurrentImage(index);
        setViewerIsOpen(true);
      }, []);
    
      const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
      };

    // const blogURL = `/blog/${props.title.split(' ').join('-').toLocaleLowerCase()}?id=${props.id}`;
    return (
        <div className={props.cols ? props.cols : 'col-md-6 col-lg-4'}>
            <div className="blog-item">
                {
                    props.thumb ? (
                        <figure className="blog-thumb">
                                <img onClick={() => openLightbox(0)} src={require('../../assets/img/' + props.thumb)} alt={props.title} />
                        </figure>
                    ) : null
                }

                {viewerIsOpen ? (
                    <ModalGateway>
                        <Modal onClose={closeLightbox}>
                            <Carousel currentIndex={currentImage} views={images} />
                        </Modal>
                    </ModalGateway>
                ) : null}
            </div>
        </div>
    );
}

export default BlogItem;