import React from "react";
import SlickSlider from "../../UI/Slick";
import SliderData from "../../../data/Slider/home-1";
import { Link } from "react-router-dom";
import { useEffect, useRef, useImperativeHandle } from "react";

const NextArrow = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <i className="fa fa-angle-right" />
    </button>
  );
};

const PrevArrow = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <i className="fa fa-angle-left" />
    </button>
  );
};

const Slider = (props) => {
  const sliderRef = useRef();

  const settings = {
    arrows: true,
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    ref: sliderRef,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  useEffect(() => {
    if(props){
      if(props.title == 'Home'){
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(0);
        }
      }
    }
  }, [props])

  return (
    <div className={"slider-area"}>
      <SlickSlider settings={settings}>
        {SliderData.map((item, index) => (
          <div key={item.id}>
            {/* {index === 0 ? (
              <video className="slider-video w-100" autoPlay loop muted>
                <source
                  src={require("../../../assets/video/banner.mp4")}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            ) : ( */}
              <div
                className="slider-item"
                style={{
                  backgroundImage: `url(${require("../../../assets/img/" +
                    item.bg)})`,
                }}
              >
                <div className="container">
                  <div className="row" style={{justifyContent: index == 2 ? 'end' : '', textAlign: index == 2 ? 'end' : ''}}>
                    <div className={`${index == 0 ? "col-xl-10" : "col-xl-7"} ${index == 1 ? "col-xl-10" : "col-xl-7"}`}>
                      <div className="slider-content">
                        <h2 style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)'}} className="text-white">{item.title}</h2>
                        <p style={{textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)'}} className="text-white">{item.text}</p>
                        {item.btnText ? <Link
                          to={`${process.env.PUBLIC_URL + item.btnLink}`}
                          className="btn btn-brand"
                        >
                          {item.btnText}
                        </Link> : null }
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            {/* )} */}
          </div>
        ))}
      </SlickSlider>
    </div>
  );
};
export default Slider;
