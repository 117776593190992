import React, { Fragment, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Support from "../components/Support/Support";

export default function Disclaimer() {
  const [title, setTitle] = useState("");

  const handleTitleClick = (clickedTitle) => {
    setTitle(clickedTitle);
  };
  return (
    <Fragment>
      <Header onTitleClick={handleTitleClick} />
      <div
        className="container d-flex justify-content-center align-items-center flex-column"
        style={{ height: "500px" }}
      >
        <h2>Our Disclaimer</h2>
        <p>
          The presentation is for education purpose only and doesn’t contain
          investment advise.
        </p>
        <p>
          Trading Derivatives markets carries a high level of risk, and may not
          be suitable for all investors/traders.{" "}
        </p>
        <p>Past performance is not indicative of futures results.</p>
      </div>
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
}
