import React,{Fragment, useState} from 'react';
import Header from "../components/Header";
import ServiceDetails from "../templates/ServiceDetails";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import StylesheetComponent from '../components/StylesheetComponent';
import BrandLogo from "../components/BrandLogo";

const PageServiceDetails = () => {
    const [title, setTitle] = useState('');

    const handleTitleClick = (clickedTitle) => {
        setTitle(clickedTitle);
    };
    return (
        <Fragment>
            <StylesheetComponent color={'white'} />
            <Header onTitleClick={handleTitleClick}/>
            <ServiceDetails/>
            {/* <BrandLogo/> */}
            {/* <Funfact/> */}
            <CallToAction/>
            <Footer/>
            <LoginRegister/>
            <MobileMenu onTitleClick={handleTitleClick}/>
        </Fragment>
    );
};

export default PageServiceDetails;