import React, {Component, Fragment} from 'react';
import BlogItem from "../../components/Blog/blogItem";
import BlogItemList from "../../components/Blog/blogItemList";
import Pagination from "../../components/Pagination";

import BlogData from '../../data/Certificates/certificates'

class CertificatesContent extends Component {
    state = {
        posts: [],
        currentPage: 1,
        postsPerPage: 6
    };

    componentDidMount() {
        this.setState({
            posts: BlogData
        })
    }

    render() {
        // Get current posts
        const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
        const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
        const currentPosts = this.state.posts.slice(indexOfFirstPost, indexOfLastPost);

        // Change page
        const paginate = currentPage => this.setState({
            currentPage: currentPage
        });
        return (
            <div className={this.props.cols + ' ' + this.props.classes}>
            <h2 style={{fontWeight: '500'}}>Team Certificates</h2>
                <Fragment>
                    <div className={`blog-content-wrapper ${this.props.blog_type === 'list' && 'blog-list'}`}>
                        <div className="row mtn-30">
                            {                  
                                    currentPosts.map(blog => (
                                        <BlogItem
                                            key={blog.id}
                                            id={blog.id}
                                            cols={this.props.cols === 'col-12' ? 'col-md-6 col-lg-4' : 'col-md-6'}
                                            title={blog.title}
                                            thumb={blog.image}
                                        />
                                    ))
                            }
                        </div>
                    </div>

                    <Pagination
                        postsPerPage={this.state.postsPerPage}
                        totalPosts={this.state.posts.length}
                        paginate={paginate}
                        currentPage={this.state.currentPage}
                    />
                </Fragment>
            </div>
        );
    }
}

export default CertificatesContent;