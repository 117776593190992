import React from "react";
// Importing the logos, assuming you have them saved as these names.
import whatsappLogo from "../../assets/img/social/whatsapp.png";
import telegramLogo from "../../assets/img/social/telegram.png";
import gmailLogo from "../../assets/img/social/gmail.png";

import supportImage from "../../assets/img/support.png";

export default function Support() {
  return (
    <>
      <div style={{marginBottom: '3.5rem', marginTop: '6rem'}} className="container">
        <div className="row">
          <div className="col-md-6 d-flex justify-content-center flex-column">
            <h2 className="mb-4">
              <span style={{ color: "rgb(14, 35, 156)" }}>Connect</span> With Us
            </h2>
            <div className="d-flex flex-column">
              <div
                style={{
                  backgroundColor: "rgb(239, 235, 235)",
                  maxWidth: "500px",
                  borderRadius: "15px",
                }}
                className="card social-card mb-3 border-0"
                onClick={() => {
                  /* Handle WhatsApp action here */
                }}
              >
                <div className="d-flex align-items-center p-2 justify-content-between">
                  <div>
                    <img
                      src={whatsappLogo}
                      alt="WhatsApp"
                      width="40"
                      className="mr-3"
                    />
                    <span
                      style={{ fontFamily: "system-ui", fontWeight: "500" }}
                    >
                      WhatsApp
                    </span>
                  </div>
                  <button
                    style={{ borderRadius: "15px", backgroundColor: "#25D366" }}
                    className="btn btn-primary border-0"
                  >
                    Chat Now
                  </button>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "rgb(239, 235, 235)",
                  maxWidth: "500px",
                  borderRadius: "15px",
                }}
                className="card social-card mb-3 border-0"
                onClick={() => {
                  /* Handle Telegram action here */
                }}
              >
                <div className="d-flex align-items-center p-2 justify-content-between">
                  <div>
                    <img
                      src={telegramLogo}
                      alt="Telegram"
                      width="40"
                      className="mr-3"
                    />
                    <span
                      style={{ fontFamily: "system-ui", fontWeight: "500" }}
                    >
                      Telegram
                    </span>
                  </div>
                  <button
                    style={{ borderRadius: "15px", backgroundColor: "#229ED9" }}
                    className="btn btn-primary border-0"
                  >
                    Chat Now
                  </button>
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "rgb(239, 235, 235)",
                  maxWidth: "500px",
                  borderRadius: "15px",
                }}
                className="card social-card mb-3 border-0"
                onClick={() => {
                  /* Handle Gmail action here */
                }}
              >
                <div className="d-flex align-items-center p-2 justify-content-between">
                  <div>
                    <img
                      src={gmailLogo}
                      alt="Gmail"
                      width="40"
                      className="mr-3"
                    />
                    <span
                      style={{ fontFamily: "system-ui", fontWeight: "500" }}
                    >
                      Gmail
                    </span>
                  </div>
                  <button
                    style={{ borderRadius: "15px", backgroundColor: "#EA4335" }}
                    className="btn btn-primary border-0"
                  >
                    Chat Now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <img src={supportImage} alt="Support Image" className="img-fluid" />
          </div>
        </div>
      </div>
    </>
  );
}
