import React from 'react';
import PropTypes from 'prop-types';

const StylesheetComponent = ({ color }) => {
  const styles = `
    .header .header-text {
      color: ${color}!important;
    }

    .header .main-menu li a {
      color: ${color}!important;
    }
  `;

  return <style>{styles}</style>;
};

export default StylesheetComponent;
