import React,{Component} from 'react';
import Logo from './Logo'
import Navbar from './Navbar/Navbar'
import HeaderConfig from './HeaderConfig'
import '../../header.css'
import logo from '../../assets/img/logo/logo.png'

class Header extends Component{

    handleTitleClick = (title) => {
        this.props.onTitleClick(title);
    };

    componentDidMount() {
        window.addEventListener('scroll', this.isSticky);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.isSticky);
    }

    isSticky = (e)=>{
        const header = document.querySelector('header');
        const scrollTop = window.scrollY;
        scrollTop >= 12 ? header.classList.remove('header') : header.classList.add('header')
        scrollTop >= 12 ? header.classList.add('sticky') : header.classList.remove('sticky');
    };

    render(){
        const currentPath = window.location.pathname;
        
        let customStyle = { padding: '5px 0px' }; // Default style

        if (currentPath === '/support') {
            customStyle = { ...customStyle, top: 0 };
        } else {
            
        }

        return (
            <header style={customStyle} className="header-area header">
                <div className="container">
                    <div style={{alignItems: 'baseline'}} className="row">
                        <div style={{fontFamily: 'unset'}} className="col-5 col-lg-2 text-dark header-text nav-text">
                        <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
                        <a href="/"><img className='nav-logo' src={logo} alt="MarketCraftAcademy-Logo" /></a>
                            MarketCraftAcademy
                            </div>
                        </div>

                        <div className="col-lg-10 d-none d-lg-block">
                            <div className="navigation-area mt-lg-3">
                                <Navbar onTitleClick={this.handleTitleClick} />
                            </div>
                        </div>

                        <div className="col-7 col-lg-2 d-flex justify-content-end align-items-center d-lg-none">
                            <HeaderConfig logRegContentShow={this.props.logRegContentShow} mobileMenuShow={this.props.mobileMenuShow} />
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;