import React,{Fragment, useState} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About/page";
import TeamMember from '../templates/Team'
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

import ServiceThumb from '../assets/img/about.jpg'
import StylesheetComponent from '../components/StylesheetComponent';

const PageTeam = () => {
    const [title, setTitle] = useState('');

    const handleTitleClick = (clickedTitle) => {
        setTitle(clickedTitle);
    };
    return (
        <Fragment>
            <StylesheetComponent color={'white'} />
            <Header onTitleClick={handleTitleClick}/>
            <PageHeader
                bgImg={require('../assets/img/teams-header.jpg')}
                title="OUR TEAM"
                content="Meet the Minds: The Dedicated Experts Steering Market Craft's Vision."
            />
            <About
                title={'Our Team'}
                heading="Meet Our <br/> Expert Members"
                thumb={ServiceThumb}
                content="Behind every successful trade is a team of seasoned professionals. Discover the passion, expertise, and dedication that defines the <b>Market Craft family</b>."
            />
            <TeamMember/>
            {/* <BrandLogo/> */}
            <Funfact/>
            <CallToAction/>
            <Footer/>
            <LoginRegister/>
            <MobileMenu onTitleClick={handleTitleClick}/>
        </Fragment>
    );
};

export default PageTeam;