import React, { Fragment } from "react";
import PageWrapper from "../../components/PageWrapper";
import PageHeader from "../../components/PageHeader";
import BlogContent from "./BlogContent";
import SidebarForBlog from "../SidebarForBlog";
import GalleryContent from "../Gallery/GalleryContent";
import CertificatesContent from "../Gallery/CertificatesContent";

const BlogPage = ({ sidebar_position, blog_type, sidebar }) => {
  return (
    <Fragment>
      <PageHeader
        bgImg={require("../../assets/img/gallery-banner-2.jpg")}
        title={"From Market Craft Academy"}
        content={""}
      />

      <PageWrapper classes={'blog-page-content-area sp-y'}>
                {sidebar === true && sidebar_position === 'left' ? <SidebarForBlog classes={'order-1 order-lg-0'}/> : null}

                <CertificatesContent blog_type={blog_type} cols={sidebar ? 'col-lg-9' : 'col-12'} classes={sidebar_position === 'left' ? 'order-0 order-lg-1':null}/>
                <GalleryContent blog_type={blog_type} cols={sidebar ? 'col-lg-9' : 'col-12'} classes={sidebar_position === 'left' ? 'order-0 order-lg-1':null}/>
                {/* <BlogContent blog_type={blog_type} cols={sidebar ? 'col-lg-9' : 'col-12'} classes={sidebar_position === 'left' ? 'order-0 order-lg-1':null}/> */}

                {sidebar === true && sidebar_position === 'right' ? <SidebarForBlog/> : null}
            </PageWrapper>
    </Fragment>
  );
};

export default BlogPage;
