import React from 'react';
import PropTypes from 'prop-types';

const IndexStylesheetComponent = () => {
  const styles = `
    .header {
      background-color: rgba(255, 255, 255, 0.6)!important;
      margin-top: 4px;      
    }
  `;

  return <style>{styles}</style>;
};

export default IndexStylesheetComponent;
