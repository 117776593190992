import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

const From = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);
    formData.append("_captcha", false);

    const url = "https://formsubmit.co/marketcraftt@gmail.com";
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.status == 200) {
          toast.success("Form submitted successfully.", { duration: 2000 });
          setName("");
          setEmail("");
          setMessage("");
        }
      })
      .catch((error) => {
        toast.error(
          "An error occurred. Please try reaching out on contact number.",
          { duration: 3500 }
        );
      });
  };

  return (
    <>
      <div className="contact-form-wrap">
        <form id="contact-form" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6 single-input-item">
              <input
                type={"text"}
                name={"name"}
                placeholder={"Name *"}
                value={name}
                required
                onChange={handleInputChange}
              />
            </div>

            <div className="col-md-6 single-input-item">
              <input
                type={"email"}
                name={"email"}
                placeholder={"Email address *"}
                value={email}
                required
                onChange={handleInputChange}
              />
            </div>

            <div className="col-12 single-input-item">
              <textarea
                rows="7"
                name={"message"}
                placeholder={"Write Your Message *"}
                value={message}
                required
                onChange={handleInputChange}
              />

              <button type="submit" className="btn-outline mt-4">
                Send Message
              </button>

              <div className="form-message" />
            </div>
          </div>
        </form>
      </div>
      <Toaster />
    </>
  );
};

export default From;
