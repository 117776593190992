import React, { Fragment, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Support from "../components/Support/Support";

const ComingSoon = () => {
  const [title, setTitle] = useState("");

  const handleTitleClick = (clickedTitle) => {
    setTitle(clickedTitle);
  };
  return (
    <Fragment>
      <Header onTitleClick={handleTitleClick} />
      <div className="container d-flex justify-content-center align-items-center" style={{height: '500px'}}>
        <h2 className="mb-4">
          <span style={{ color: "rgb(14, 35, 156)" }}>Coming</span> Soon
        </h2>
      </div>
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default ComingSoon;
