import React from "react";
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo-dark.png";

import instagramLogo from "../../assets/img/social/instagram.png";
import facebookLogo from "../../assets/img/social/facebook.png";
import twitterLogo from "../../assets/img/social/twitter.png";

function Footer() {
  return (
    <footer
      style={{ backgroundColor: "#0D0D2B" }}
      className="footer-area sp-bottom"
    >
      <div className="container">
        <div className="row mtn-40">
          <div className="col-lg-4 order-4 order-lg-0">
            <div className="widget-item">
              <div className="about-widget">
                <Link
                  style={{ fontSize: "1.5rem", fontFamily: "unset" }}
                  className="text-light"
                  to={`${process.env.PUBLIC_URL + "/"}`}
                >
                  {/* <img src={Logo} alt="Logo"/> */}
                  MarketCraftAcademy
                </Link>

                <p className="fs-12 text-light mt-4">
                  Step into the world of stock market mastery with
                  MarketCraftAcademy, where our fervent commitment to unraveling
                  the intricacies of stock trading knows no bounds.
                </p>

                <Text classes="copyright-txt text-white">
                  &copy; {new Date().getFullYear()} MarketCraftAcademy. All
                  Rights Reserved.
                </Text>

                <Text classes="copyright-txt text-white">
                  Powered By <a href="https://weblabs.studio" target="_blank" style={{color: 'lightblue', cursor: 'pointer'}}>Weblabs Studio</a>
                </Text>
              </div>
            </div>
          </div>

          <div className="col-md-3 col-lg-3 ml-auto">
            <Widget title="Explore">
              <List classes="widget-list">
                <LI>
                  <Link
                    style={{ fontWeight: "300" }}
                    className="text-white"
                    to={`${process.env.PUBLIC_URL + "/about"}`}
                  >
                    Our company
                  </Link>
                </LI>
                <LI>
                  <Link
                    style={{ fontWeight: "300" }}
                    className="text-white"
                    to={`${process.env.PUBLIC_URL + "/contact"}`}
                  >
                    Contact us
                  </Link>
                </LI>
                <LI>
                  <Link
                    style={{ fontWeight: "300" }}
                    className="text-white"
                    to={`${process.env.PUBLIC_URL + "/courses"}`}
                  >
                    Our Courses
                  </Link>
                </LI>
                <LI>
                  <Link
                    style={{ fontWeight: "300" }}
                    className="text-white"
                    to={`${process.env.PUBLIC_URL + "/coming-soon"}`}
                  >
                    Our Careers
                  </Link>
                </LI>
                <LI>
                  <Link
                    style={{ fontWeight: "300" }}
                    className="text-white"
                    to={`${process.env.PUBLIC_URL + "/disclaimer"}`}
                  >
                    Our Disclaimer
                  </Link>
                </LI>
              </List>
            </Widget>
          </div>

          {/* <div className="col-md-4 col-lg-2 ml-auto">
                        <Widget title="Social Links">
                            <List classes="widget-list">
                                <LI><Link style={{fontWeight: '300'}} className="text-white" to="https://facebook.com/hasthemes/" target={'_blank'}><img style={{maxWidth: '25px'}} src={facebookLogo} /></Link></LI>
                                <LI><Link style={{fontWeight: '300'}} className="text-white" to="https://twitter.com/hasthemes/" target={'_blank'}><img style={{maxWidth: '25px'}} src={twitterLogo} /></Link></LI>
                                <LI><Link style={{fontWeight: '300'}} className="text-white" to="https://www.instagram.com/" target={'_blank'}><img style={{maxWidth: '25px'}} src={instagramLogo} /></Link></LI>
                            </List>
                        </Widget>
                    </div> */}

          <div className="col-md-4 col-lg-3">
            <Widget className="text-white" title="Contact Us">
              <address style={{ fontWeight: "300" }} className="text-white">
                G-2 Plot No 52 Civil Lines, Shyamla Hills, Bhopal Madhya Pradesh
                India - 462002 <br />
                (+91) 7489108446
              </address>
            </Widget>
          </div>
        </div>

        <div
          style={{ gap: "15px" }}
          className="mt-4 d-flex justify-content-end"
        >
          <Link
            className="text-white cursor-pointer"
            to="https://facebook.com/hasthemes/"
            target={"_blank"}
          >
            <img style={{ maxWidth: "25px" }} src={facebookLogo} />
          </Link>
          <Link
            className="text-white cursor-pointer"
            to="https://twitter.com/hasthemes/"
            target={"_blank"}
          >
            <img style={{ maxWidth: "25px" }} src={twitterLogo} />
          </Link>
          <Link
            className="text-white cursor-pointer"
            to="https://www.instagram.com/"
            target={"_blank"}
          >
            <img style={{ maxWidth: "25px" }} src={instagramLogo} />
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
