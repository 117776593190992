import React from 'react';
import NavbarItem from './NavbarItem'

function Navbar(props) {

    return (
        <ul className="main-menu nav">
            <NavbarItem onTitleClick={props.onTitleClick} />
        </ul>
    );
}

export default Navbar;