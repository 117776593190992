import React from "react";
import parse from "html-react-parser";
import aboutData from "../../../data/About/home-two";
import aboutThumb from "../../../assets/img/about-bg.jpg";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <>
      <div
        className="home-two-about-area"
        style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url(${aboutThumb})`
    }}
      >
        <div className="container">
          <div className="row align-items-center">
            {/* <div className="col-12 d-lg-none">
              <figure className="about-thumb">
                <img
                  src={require("../../../assets/img/" + aboutData.thumb)}
                  alt="Businex-About"
                />
              </figure>
            </div> */}

            <div className="col-lg-6">
              <div className="about-content about-content--2">
                <h6>{aboutData.title}</h6>
                <h2>{parse(aboutData.heading)}</h2>
                <span className="about-since">{aboutData.since}</span>
                <p>{parse(aboutData.text)}</p>
                {/* <Link to={`${process.env.PUBLIC_URL + aboutData.btnLink}`} className="btn-about">{aboutData.btnText} <i className="fa fa-angle-double-right"/></Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-two-about-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="about-content about-content--2 pt-2">
                <h3>Our Mission</h3>
                <p>
                  Our mission, profound in its simplicity, embodies a profound
                  aspiration: to proffer superlative stock market trading
                  education, thus arming our disciples with the discernment,
                  prowess, and unshakeable self-assurance required to flourish
                  in the realm of finance. We are steadfast in our resolve to
                  dispel the enigma surrounding the stock market, ushering forth
                  an era of inclusivity where financial enlightenment is
                  attainable irrespective of one's background or prior
                  experience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home-two-about-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="about-content about-content--2 pt-2">
                <h3>Why Opt for Our Eminence?</h3>
                <p>
                  <strong>Sage Tutors:</strong> Enlist in the tutelage of
                  distinguished traders and seasoned financial sages,
                  individuals whose luminous careers have carved their names
                  indelibly into the annals of finance. At{" "}
                  <strong>Market Craft Academy</strong>, you learn from
                  luminaries.
                </p>

                <p>
                  <strong>Holistic Syllabus:</strong> Traverse a meticulously
                  tailored curriculum, a labyrinthine journey that navigates
                  from the rudiments of stock trading to the acme of strategic
                  prowess. We cater to neophytes and virtuosos alike.
                </p>

                <p>
                  <strong>Experiential Pedagogy:</strong> Experience pedagogy
                  transcending the bounds of mere theory. Our interactive
                  classes empower you to engross yourself in realistic
                  simulations and authentic market analysis.
                </p>

                <p>
                  <strong>Communal Bond:</strong> Akin spirits unite within our
                  hallowed halls. Forge connections with kindred souls who share
                  your fervor for trading. Inquire, deliberate, and burgeon
                  collectively.
                </p>

                <p>
                  <strong>Bespoke Guidance:</strong> Acknowledging your
                  distinctiveness, we extend personalized counsel and mentorship
                  from our sagacious instructors. Your aspirations are our
                  guiding star.
                </p>

                <h5 className="mt-5">Our Pledge</h5>

                <p>
                  At <strong>Market Craft Academy</strong> , your prosperity
                  becomes the touchstone of our triumph. We gauge our
                  achievements by your victories. Whether your aspirations
                  entail wealth accumulation, the fortification of your
                  financial future, or the illumination of stock market
                  intricacies, we are your unwavering companions on this
                  odyssey.
                </p>

                <h5 className="mt-3">Embark on This Expedition</h5>

                <p>
                  Embark upon the voyage to financial emancipation in the
                  company of <strong>Market Craft Academy</strong>. We extend
                  an invitation to peruse our diverse array of courses,
                  resources, and the camaraderie of our community. Irrespective
                  of your station in the trading pilgrimage, there is a niche
                  awaiting your presence. Together, we shall unlock the vast
                  potential of the stock market and escort you to the pinnacles
                  of your financial ambitions.
                </p>

                <p>
                  Eager to embark on this voyage? Kindly initiate contact with
                  us today for further elucidation regarding our courses and how
                  we shall serve as the wind beneath your wings on your quest to
                  become a self-assured and prosperous stock market virtuoso.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
