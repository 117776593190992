import React,{Fragment, useState} from 'react';
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import Services from "../components/Services";
import PricingTable from "../components/PricingTable";
import Testimonial from "../components/Testimonials/home-one";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import StylesheetComponent from '../components/StylesheetComponent';
import ServiceThumb from '../assets/img/about.jpg'

const PageService = () => {
    const [title, setTitle] = useState('');

    const handleTitleClick = (clickedTitle) => {
        setTitle(clickedTitle);
    };

    return (
        <Fragment>
            <StylesheetComponent color={'white'} />
            <Header onTitleClick={handleTitleClick}/>
            <PageHeader
                bgImg={require('../assets/img/courses-banner.jpg')}
                title="OUR COURSES"
                content="Unlock Trading Excellence: Dive into curated courses tailored for every investor's journey."
            />
            <PageAbout
                title={'Our Courses'}
                heading="Trading Mastery <br/> Awaits"
                thumb={ServiceThumb}
                content="At <b>Market Craft Academy</b>, we believe in enlightening our traders. Delve deep into a vast reservoir of courses, meticulously structured to arm you with profound insights, strategies, and expertise. Our commitment is beyond mere knowledge; it's about shaping visionary traders who can navigate the intricate waves of the financial markets with finesse and acumen."
            />
            <Services classes="sm-top"/>
            {/* <PricingTable/> */}
            <Testimonial/>
            {/* <BrandLogo/> */}
            <Funfact classes="sp-top"/>
            <CallToAction/>
            <Footer/>
            <LoginRegister/>
            <MobileMenu onTitleClick={handleTitleClick}/>
        </Fragment>
    );
};

export default PageService;