import React,{Fragment, useState} from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Support from '../components/Support/Support';

const PageSupport = () => {
    const [title, setTitle] = useState('');

    const handleTitleClick = (clickedTitle) => {
        setTitle(clickedTitle);
    };
    return (
        <Fragment>
            <Header onTitleClick={handleTitleClick}/>
            <Support />
            <Footer/>
            <LoginRegister/>
            <MobileMenu onTitleClick={handleTitleClick}/>
        </Fragment>
    );
};

export default PageSupport;