import React from 'react';
import SlickSlider from "../../components/UI/Slick";
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";
import IconBox from "../../components/IconBox";
import Text from "../../components/UI/Text";
import parse from "html-react-parser";

import HowWeWorksData from "../../data/HowWeWorks/how-we-works";

const ServiceContentWrap = ({service,totalService,prevService,nextService}) => {
    return (
        <div className="col-lg-8">
            <div className="service-details-content">
                <div className="service-thumbnail-wrapper">
                    <SlickSlider settings={{
                        className: "service-details-thumb",
                        arrows: false,
                        dots: true
                    }}>
                        {
                            service.previewImages.map((previewImg, indx) => (
                                <div key={indx}>
                                    <img src={require('../../assets/img/' + previewImg)} alt={service.title} />
                                </div>
                            ))
                        }
                    </SlickSlider>
                </div>

                <div className="service-details-info">
                    <h3 className="mb-1">{service.title}</h3>
                    <h5>Price: INR <strong>{service.price}</strong></h5>
                    <h5>Duration: <strong>{service.duration}</strong></h5>
                    <p className="mt-3">{service.shortDesc}</p>

                    <div className="about-service mt-50 mt-sm-35">
                        <h4>About this Course</h4>
                        {
                            <div dangerouslySetInnerHTML={{__html: service.aboutServiceDesc}}/>
                        }
                        <h5 className='mt-40'>{service.featureTitle}</h5>
                        <List classes="service-feature mt-20">
                            {
                                service.features.map((feature,indx)=>(
                                    <LI key={indx}>{parse(feature)}</LI>
                                ))
                            }
                        </List>
                    </div>

                    <div className="how-we-works-wrap mt-50 mt-sm-35">
                        {parse(service.postDescription)}
                    </div>

                    <div className="pagination-wrap">
                        <ul className="pagination">
                            <li className="prev">
                                <a href={`${process.env.PUBLIC_URL + `/course/${prevService.title.split(' ').join('-').toLocaleLowerCase()}?id=${prevService.id}`}`}
                                   className={service.id === 1 ? "disabled" : null}
                                >
                                    <i className="fa fa-long-arrow-left"/> Previous
                                </a>
                            </li>
                            <li className="next">
                                <a href={`${process.env.PUBLIC_URL + `/course/${nextService.title.split(' ').join('-').toLocaleLowerCase()}?id=${nextService.id}`}`}
                                   className={service.id === totalService ? "disabled" : null}
                                >
                                    Next <i className="fa fa-long-arrow-right"/>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceContentWrap;