import React from "react";
import "./CourseCard.css";
import SectionTitle from "../UI/SectionTitle";
import { useState } from "react";

const CourseCard = (props) => {
  const [showAll, setShowAll] = useState(false)
  return (
    <section
      style={{ marginTop: "5rem" }}
      id="pricing"
      className="pricing-content section-padding"
    >
      <div className="container">
        <div className="section-title text-center">
        <SectionTitle
            title="OUR COURSES"
            heading="Master the Markets: Courses for Every Trader"
          />
        </div>
        <div className="row text-center">
          <div
            className="col-lg-4 col-sm-4 col-xs-12 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.1s"
            data-wow-offset="0"
          >
            <div className="single-pricing single-pricing-white">
              <div className="price-head">
                <h2>Advanced Courses</h2>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              {/* <h1 className="price">$29</h1>
              <h5>Monthly</h5> */}
              <ul>
                <li>Trading Realms</li>
                <hr style={{borderTop: '0.5px solid lightGray', margin: '0 0'}} />
                <li>Elite Stock Market</li>
                <hr style={{borderTop: '0.5px solid lightGray', margin: '0 0'}} />
                <li>Comprehensive Stock Market</li>
              </ul>
              <a className="pricing-btn" href="/courses">Get started</a>
            </div>
          </div>
          <div
            className="col-lg-4 col-sm-4 col-xs-12 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
            data-wow-offset="0"
          >
            <div className="single-pricing single-pricing-white">
              <div className="price-head">
                <h2>Placement Assistance Courses</h2>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              {/* <span className="price-label">Best</span> */}
              {/* <h1 className="price">$69</h1>
              <h5>Monthly</h5> */}
              <ul>
                <li>NCFM - Technical Analysis Module</li>
                <hr style={{borderTop: '0.5px solid lightGray', margin: '0 0'}} />
                <li>NISM-Series-VII: Securities Operations and Risk Management Certification Examination</li>
                <hr style={{borderTop: '0.5px solid lightGray', margin: '0 0'}} />
                <li>NISM Series-IX: Merchant Banking Certification Examination</li>
                {showAll && 
                <>
                  <hr style={{borderTop: '0.5px solid lightGray', margin: '0 0'}} />
                <li>NISM-Series-V-A: Mutual Fund Distributors Certification Examination</li>
                <hr style={{borderTop: '0.5px solid lightGray', margin: '0 0'}} />
                <li>NISM-Series-XV: Research Analyst Certification Examination</li>
                <hr style={{borderTop: '0.5px solid lightGray', margin: '0 0'}} />
                <li>NISM-Series-VIII: Equity Derivatives Certification Examination</li>
                <hr style={{borderTop: '0.5px solid lightGray', margin: '0 0'}} />
                <li>NISM-Series-X-A: Investment Adviser (Level 1) Certification Examination</li>
                </>}
              
              </ul>
              <a className="pricing-btn" onClick={() => setShowAll(true)}>Get started</a>
            </div>
          </div>
          <div
            className="col-lg-4 col-sm-4 col-xs-12 wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
            data-wow-offset="0"
          >
            <div className="single-pricing single-pricing-white">
              <div className="price-head">
                <h2>Premium Courses</h2>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              {/* <h1 className="price">$49</h1>
              <h5>Monthly</h5> */}
              <ul>
                <li>Technical Analysis</li>
                <hr style={{borderTop: '0.5px solid lightGray', margin: '0 0'}} />
                <li>Options Unveiled</li>
                <hr style={{borderTop: '0.5px solid lightGray', margin: '0 0'}} />
                <li>Portfolio Construction Guide</li>
              </ul>
              <a className="pricing-btn" href="/courses">Get started</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseCard;
