import React from "react";

const MemberInfo = ({ teamMember }) => {
  const {
    profilePic,
    name,
    designation,
    bio,
    socials,
    address,
    phone,
    email,
    website,
    fullDesignation,
    guestFaculty,
    certificate,
  } = teamMember;
  return (
    <div className="member-details-top">
      <div className="row">
        <div className="col-md-5">
          <div className="member-pic mb-sm-35">
            <img src={require("../../assets/img/" + profilePic)} alt={name} />
          </div>
        </div>

        <div className="col-md-7 ml-auto">
          <div className="member-desc">
            <h2>{name}</h2>
            <h5 className={fullDesignation ? "mb-1" : ""}>{designation}</h5>
            {fullDesignation ? (
              <h6 className="text-muted mb-3" style={{ fontSize: "14px" }}>
                {fullDesignation}
              </h6>
            ) : null}
            {guestFaculty ? <span className="mb-3">{guestFaculty}</span> : null}
            {bio ? <p dangerouslySetInnerHTML={{ __html: bio }} /> : null}

            {socials ? (
              <div className="member-social-icons mt-25">
                <a
                  href={`https://facebook.com/${socials.facebook}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="facebook"
                >
                  <i className="fa fa-facebook" />
                </a>
                <a
                  href={`https://twitter.com/${socials.twitter}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="twitter"
                >
                  <i className="fa fa-twitter" />
                </a>
                <a
                  href={`https://linkedin.com/in/${socials.linkedin}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="linkedin"
                >
                  <i className="fa fa-linkedin" />
                </a>
                <a
                  href={`https://reddit.com/${socials.reddit}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="reddit"
                >
                  <i className="fa fa-reddit" />
                </a>
                <a
                  href={`https://pinterest.com/${socials.pinterest}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pinterest"
                >
                  <i className="fa fa-pinterest" />
                </a>
              </div>
            ) : null}

            <div className="contact-info mt-25">
              {address ? (
                <p>
                  <strong>Address</strong>{" "}
                  <span dangerouslySetInnerHTML={{ __html: address }} />
                </p>
              ) : null}
              {phone ? (
                <p>
                  <strong>Phone</strong> {phone}
                </p>
              ) : null}
              {email ? (
                <p>
                  <strong>Email</strong> {email}
                </p>
              ) : null}
              {website ? (
                <p>
                  <strong>Web</strong> {website}
                </p>
              ) : null}
            </div>
          </div>
        </div>

        {certificate ? (
          <div style={{ padding: "15px" }}>
            <h3>Taha's Certificates</h3>

            <div className="row">
              {certificate.map((ele, index) => (
                <div key={index} className="col-md-3">
                  <a href={require('../../assets/img/' + ele.certificate)} target="_blank" rel="noopener noreferrer">
                    <img
                      src={require('../../assets/img/' + ele.thumbnail)}
                      alt={`Certificate ${index + 1}`}
                      className="img-fluid"
                    />
                  </a>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MemberInfo;
