import React,{Fragment, useState} from 'react';
import Header from "../components/Header";
import BlogPage from "../templates/Blog";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import CallToAction from "../components/CallToAction";
import StylesheetComponent from '../components/StylesheetComponent';

const PageBlogGrid = () => {
    const [title, setTitle] = useState('');

    const handleTitleClick = (clickedTitle) => {
        setTitle(clickedTitle);
    };

    return (
        <Fragment>
            <StylesheetComponent color={'white'} />
            <Header onTitleClick={handleTitleClick} />
            <BlogPage blog_type={'grid'} sidebar={false} sidebar_position={'left'}/>
            <CallToAction/>
            <Footer/>
            <LoginRegister/>
            <MobileMenu onTitleClick={handleTitleClick}/>
        </Fragment>
    );
};

export default PageBlogGrid;